#wall {
    height: calc(100vh - 1em);
}

.wallTable {
    display: table;
}

.wallRow {
    line-height: 0;
    height: calc(var(--cell-height) + var(--cell-border));
}

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

:root {
    --page-background-color: #bbb;
}

html, #root {
    background-color: var(--page-background-color);
}

#root {
    padding: 0.5em;
    min-height: 100vh;
}


